import { bscTestnetTokens, bscTokens, onusMainnetTokens } from "@pancakeswap/tokens";
import { ChainId } from '@pancakeswap/sdk';

export const renderToken = (chainId: number) => {
    switch (chainId) {
        case ChainId.BSC:
            return bscTokens.VNDC;
        case ChainId.ONUS:
            return onusMainnetTokens.vndc;
        default:
            return bscTestnetTokens.vndc;
    }
}